import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Dropdown, Placeholder, Tabs, Whisper, Popover, Button, Tooltip } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'rsuite/dist/rsuite.min.css';
import debounce from 'lodash.debounce';


function ETFHoldingsBasket() {

  const [tabName, setTabName] = useState('ETF Holdings Basket');
    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

  const data = ['Cash', 'Bond', 'Derivative', 'Stock', 'ETF', 'Cash (Shariah-Compliant)', 'Money Market Fund', 'Proffered Stock (Not Compliant) ', 'Other Items', 'Other'].map(
    item => ({ label: item, value: item })
);
const [manualStatus, setmanualStatus] = useState(false);
const [checkAll, setCheckAll] = useState(false);
    const [checkboxes, setCheckboxes] = useState([
        { id: 1, checked: false },
        { id: 2, checked: false },
        { id: 3, checked: false }
    ]);
    const [activeClass, setActiveClass] = useState('');

  

    const handleCheckboxChange = (id) => {
        const newCheckboxes = checkboxes.map(checkbox => {
            if (checkbox.id === id) {
                return { ...checkbox, checked: !checkbox.checked };
            }
            return checkbox;
        });
        setCheckboxes(newCheckboxes);
        setCheckAll(newCheckboxes.every(checkbox => checkbox.checked));
        setActiveClass(newCheckboxes.some(checkbox => checkbox.checked) ? 'active' : '');
    };

    useEffect(() => {
      AOS.init();
    }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchAsset, setSearchAsset] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info


  const [assetClassData, setAssetClassData] = useState([]);

  const fetchGlobalReport = async () => {
    setLoading(true);
    console.log('ENDpoint : ' + apiEndPoint);
  
    try {
      const apiUrl = `${apiEndPoint}/etf_holdings_basket`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
          q: searchInput,
          asset_class: "",
          country: "US",
          order: "DESC",
        }
      };
      console.log(JSON.stringify(requestData));
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const jsonData = await response.json();
  
      if (jsonData.data) {
        const { status, message, count, data } = jsonData.data;
  
        if (status === "success") {
          setReportCount(count);
          console.log('RES :' + searchInput + ' : ' + JSON.stringify(data));
          setReportData(data);
        } else {
          console.error("Error elese: ", message);
        }
      } else {
        console.error("Unexpected response structure:", jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };
  
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId]); // Regular effect for other dependencies

    
    const handlePageChange = (page) => {
      setCurrentPage(page);
      if (page >= startPage + 5) {
        setStartPage(page - 4);
      } else if (page < startPage) {
        setStartPage(page);
      }
    };
  
    const totalPages = Math.ceil(reportCount / 20);
    const endPage = Math.min(startPage + 4, totalPages);

    const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };

    const handleAssetClass = (value) => {
      setSearchAsset(value);
      setCurrentPage(1); // Reset to the first page for new search
    };




    const renderPagination = () => {
      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }
    
      return (
        <nav aria-label="..." id='pagination_row'>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
                <i className='fa fa-angle-double-left'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
                <i className='icon-prev'></i>
              </a>
            </li>
            {pages}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                <i className='icon-next'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
                <i className='fa fa-angle-double-right'></i>
              </a>
            </li>
          </ul>
        </nav>
      );
    };

    const handleClearFilters = () => {
      setCurrentPage(1);
      setSearchInput("");
      setSearchAsset("");
    };


    useEffect(() => {
      // Fetch the data from API
      const fetchAssetClasses = async () => {
        try {
          const response = await fetch(`${apiEndPoint}/getAssetClassDropdown`);
          const result = await response.json();
  
          if (result.data.status === 'success') {
            // Transform the data to fit SelectPicker's format
            const transformedData = result.data.data.map((item) => ({
              label: item.AssetClass,
              value: item.AssetClass,
            }));
            
            setAssetClassData(transformedData);
          }
        } catch (error) {
          console.error('Error fetching asset classes:', error);
        }
      };
  
      fetchAssetClasses();
    }, []);


// Function to handle the asset class change
const handleAssetClassChange = (id, value) => {
  // Find the relevant item in the reportData to extract the necessary data for the API call
  const selectedItem = reportData.find((item) => item.id== id);


  // Update the state with the new AssetClass value
  setReportData((prevData) =>
    prevData.map((item) =>
      item.id === id ? { ...item, AssetClass: value } : item
    )
  );

  // Prepare the data for the API request
  const apiData = {
      data: {
          user_id: userid, // You can replace this with the actual user ID if dynamic
          etf_symbol:"" , // Replace with the correct property from your data
          symbols: [selectedItem.etf_symbol],         // Replace with the correct property from your data
          asset_class: value                   // The updated asset class
      }
  };

  console.log(JSON.stringify(apiData));
  return false;
  // Make the API call to update the asset class
  fetch(`${apiEndPoint}/update_assetclass_all`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
  })
  .then(response => response.json())
  .then(data => {
      console.log('Success:', data);
      fetchGlobalReport();
      // You can handle success, maybe update some UI or state based on the response
  })
  .catch((error) => {
      console.error('Error:', error);
      // Handle errors here
  });
};


// END SHOW HIDE PASSWORD
const [activeKey, setActiveKey] = useState('HoldingBaskets');


const [activeRows, setActiveRows] = useState([]);

const handleRowClick = (id) => {
  // Toggle the active state of the clicked row
  setActiveRows((prevActiveRows) => {
    if (prevActiveRows.includes(id)) {
      // If row is already active, remove it from activeRows
      return prevActiveRows.filter((rowId) => rowId !== id);
    } else {
      // Otherwise, add it to activeRows
      return [...prevActiveRows, id];
    }
  });
};


const handleCheckAll = () => {
  const newState = !checkAll;
  setCheckAll(newState);

  if (newState) {
    // If "All" is checked, mark all rows as active
    const allRowIds = reportData.map((item) => item.id);
    setActiveRows(allRowIds);
  } else {
    // If "All" is unchecked, clear active rows
    setActiveRows([]);
  }
};


const [openDeletePopover, setOpenDeletePopover] = useState(null);
const [calculateLoading, setCalculateLoading] = useState(false);

  const handleCalculateConfirm = () => {
    setCalculateLoading(true);
  };

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETF HOLDINGS BASKET</div>
        </div>

        <div className='header_table_filter' style={{width:'auto'}}>
          {/* <Dropdown className='multiselect_drop' title={<i className='icon-minus'></i>}>
            <Dropdown.Item>Cash</Dropdown.Item>
            <Dropdown.Item>Bond</Dropdown.Item>
            <Dropdown.Item>Derivative</Dropdown.Item>
            <Dropdown.Item>Stock</Dropdown.Item>
            <Dropdown.Item>ETF</Dropdown.Item>
            <Dropdown.Item>Cash (Shariah-Compliant)</Dropdown.Item>
            <Dropdown.Item>Money Market Fund</Dropdown.Item>
            <Dropdown.Item>Proffered Stock (Not Compliant) </Dropdown.Item>
            <Dropdown.Item>Other Items</Dropdown.Item>
            <Dropdown.Item>Other</Dropdown.Item>
          </Dropdown> */}

<div className='dropdown_style table_dropdown2'>
                 <SelectPicker
                    data={assetClassData}
                    searchable={false}
                    style={{ width:180 }}
                    placeholder="All Asset classes"
                    onChange={handleAssetClass} 
                    value={searchAsset} // Bind searchAsset to the value
                    />
            </div>           
            <Whisper
                placement="buttom"
                trigger="click"
                open={openDeletePopover}
                onClose={() => setOpenDeletePopover(null)}
                speaker={
                    <Popover className="value_tooltip_form">
                        <div className="tooltip_form_inner text-center">
                            <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                            Are you sure you want to update the <br />
                            asset classes for the selected items? <br /> 
                            </div>

                            <div className='btn__group mt-5'>
                                <Button href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</Button>
                                <Button href="#" className='btn__assign' onClick={() => handleCalculateConfirm()} loading={calculateLoading}>Confirm</Button>
                            </div>
                        </div>
                    </Popover>
                }
            >
                <Button className="btn_assign" onClick={() => setOpenDeletePopover(true)}>
                    <div>
                        <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Execute the operation for selected rows</Tooltip>} >
                        <button className='btn_etf_calculate'>Apply to Selected Items</button>
                        </Whisper>
                    </div>
                </Button>
            </Whisper>


          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>

           

            <a href='javascript:void(0)' className="btn_clear_filter"  style={{textDecoration:"none"}} onClick={handleClearFilters}>Clear Filters</a>
        </div>
    </div>

    <div className="container-fluid content pb-5">
                <div className='tabs_flex_row'>
                    <Tabs activeKey={activeKey} onSelect={setActiveKey} className='TabMenu'>
                        <Tabs.Tab eventKey="HoldingBaskets" title="Holding Baskets" />
                        <Tabs.Tab eventKey="AssetClass" title="Asset Class" />
                        {/* <Tabs.Tab eventKey="APIPermissions" title="API Permissions" /> */}
                    </Tabs>

                </div>

                <div className="tab_content_main" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
                    {activeKey === 'HoldingBaskets' && (
                      <>
                  {isLoading && ( <Placeholder.Grid rows={20} columns={11} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}     
                      {/* Table */}
{!isLoading && (
                      <>
                        <div className='tab_content_inner company_table_list' style={{paddingLeft:0, paddingRight:0, minHeight:'inherit'}} >
                             <div className='table-responsive' style={{maxHeight:'inherit', height:'auto', paddingBottom:0}}>
                            <ul className='tab_btns_group'>
                                <li><a href='#' className='btn_invite_analyst' data-bs-toggle="modal" data-bs-target="#AddNewAnalystModal"><i className='icon-plus'></i>Add Analysts</a></li>
                            </ul>
                            <table className='table'>
                             <thead>
                <tr>
                  <th style={{width:50 }}>
                  <div class="form-check">
                    <input class="form-check-input" 
                    id="CheckAll"
                    type="checkbox"
                    checked={checkAll}
                    onChange={handleCheckAll}
                     />
                    <label class="form-check-label" htmlFor="CheckAll">
                      All
                    </label>
                  </div>
                  </th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th>Name</th>
                  <th>Holdings</th>
                  <th>Percent</th>
                  <th>Share</th>
                  <th>Valule</th>
                  <th style={{textAlign:'center'}}>Key</th>
                  <th style={{textAlign:'center'}}>Asset Class</th>
                </tr>
              </thead>
              
              <tbody>
                            {reportData.length > 0 ? (
  reportData.map((basket, index) => (

                <tr key={basket.id}   className={`table_row_active  ${activeRows.includes(basket.id) ? 'active' : ''}`}>
                  <td style={{width:50}}>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                      onChange={() => handleRowClick(basket.id)} // Handle click event
                      checked={activeRows.includes(basket.id)} // Mark checkbox as checked if the row is active
                       />
                    <label class="form-check-label" htmlFor={`checkbox_${basket.id}`}>
                      &nbsp;
                    </label>
                  </div>
                  </td>
                  <td style={{textAlign:'center'}}>{basket.isin}</td>
                  <td style={{textAlign:'center'}}>{basket.etf_symbol}</td>
                  <td>{basket.name}</td>
                  <td>{basket.numberOfHoldings}</td>
                  <td>{basket.percent}</td>
                  <td>{basket.share}</td>
                  <td>{basket.value}</td>
                  <td style={{textAlign:'center'}}>
                    {basket.Key}
                  </td>
                  <td style={{textAlign:'center'}}>
                  <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                      <SelectPicker
                          data={assetClassData}
                          searchable={false}
                          placeholder=""  
                          value={basket.assetType}  
                          style={{width:160}}    
                          onChange={(value) => handleAssetClassChange(basket.id, value)}                  
                          />
                  </div>
                  </td>                  
                </tr>
               ))
              ) : (
                <tr>
                   <td colSpan="11" style={{ textAlign: "center", fontSize:20, paddingTop:30, paddingBottom:30 }}>
                    No data available
                  </td>
                </tr>
              )}
              
              </tbody> 
              
              </table>   
              </div>

              {totalPages > 1 && renderPagination()}

                        </div>
                      </>
                      )}
                        </>
                    )}
                    {activeKey === 'AssetClass' && (
                      <div className='tab_content_inner'>
                        <p>No content available for Asset Class</p>
                      </div>
                    )}
                     
                </div>
            </div>     


    </>
  );
}

export default ETFHoldingsBasket;

import React, { useState,useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Dropdown, Placeholder, Whisper, Button, Popover, Message, useToaster, Tooltip } from "rsuite";
import 'rsuite/SelectPicker/styles/index.css';
import { Link  } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
import debounce from 'lodash.debounce';
import IconDelete from "../images/deleteBG.svg";

function ETFS() {

    const [tabName, setTabName] = useState('ETFs');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
    
const orderData = ['ASC', 'DESC'].map(
    item => ({ label: item, value: item })
);

const statusData = [
  { label: 'Publish', value: true },
  { label: 'UnPublish', value: false },
  { label: 'Both', value: '' }
];

useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const apiEndPoint1 = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [orderby, setOrderBy] = useState("");
  const [statusId, setStatusId] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [assetClassData, setAssetClassData] = useState([]);
  const [searchAsset, setSearchAsset] = useState("");

    const fetchGlobalReport = async () => {
      setLoading(true);
  
    try {
        const apiUrl = `${apiEndPoint}/etf_company_profile`;
        const requestData = {
          data: {
            user_id: userid,
            limit: "20",
            page: currentPage.toString(),
            q:searchInput,
            asset_class:searchAsset,
            order: orderby || "DESC",
            country: countryCode || "",
            status: complianceId,
            publish_status:statusId === 0 ? '' : statusId
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else {
          console.error("Error: ", message);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId,searchAsset,orderby]); // Regular effect for other dependencies

    
    const handlePageChange = (page) => {
      setCurrentPage(page);
      if (page >= startPage + 5) {
        setStartPage(page - 4);
      } else if (page < startPage) {
        setStartPage(page);
      }
    };
  
    const totalPages = Math.ceil(reportCount / 20);
    const endPage = Math.min(startPage + 4, totalPages);

    const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };


    const renderPagination = () => {
      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }
    
      return (
        <nav aria-label="..." id='pagination_row' style={{display:'flex', alignItems:'center', gap:60}}>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
                <i className='fa fa-angle-double-left'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
                <i className='icon-prev'></i>
              </a>
            </li>
            {pages}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                <i className='icon-next'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
                <i className='fa fa-angle-double-right'></i>
              </a>
            </li>
          </ul>

          <div style={{color:"#888"}}><span >Total Records : </span>{reportCount}</div>

        </nav>
      );
    };

    const handleClearFilters = () => {
      setCurrentPage(1);
      setSearchInput("");
      setSearchAsset("");
      setCountryCode("");
      setStatusId("");
      setComplianceId("");
      setOrderBy("");
    };

    
    const handleAssetClass = (value) => {
        setSearchAsset(value);
        setCurrentPage(1); // Reset to the first page for new search
      };


    useEffect(() => {
        // Fetch the data from API
        const fetchAssetClasses = async () => {
          try {
            const response = await fetch(`${apiEndPoint}/getAssetClassDropdown`);
            const result = await response.json();
    
            if (result.data.status === 'success') {
              // Transform the data to fit SelectPicker's format
              const transformedData = result.data.data.map((item) => ({
                label: item.AssetClass,
                value: item.AssetClass,
              }));
              
              setAssetClassData(transformedData);
            }
          } catch (error) {
            console.error('Error fetching asset classes:', error);
          }
        };
    
        fetchAssetClasses();
      }, []);


      const handleCountryChange = (value) => {
        setCurrentPage(1);
        setCountryCode(value);
      };


      useEffect(() => {
        const fetchCountryList = async () => {
          const url = `${apiEndPoint}/get_country_list_etf`;
          const payload = {
            data: {
              user_id: userid // Replace with the actual user_id if dynamic
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const responseData = await response.json();
            const data = responseData.data; // Flatten the response
    
            const formattedCountryData = data.data.map((country) => ({
              label: country.country_name,
              value: country.country_code,
            }));
    
            setCountryData(formattedCountryData);
      
          } catch (error) {
            setErrorMessage(error.message);
            setMessageType('error');
            return false;
          }
        };
      
        fetchCountryList();
      }, []);


      const handleStatusChange = (value) => {
        setStatusId(value);
      };

  const handleComplianceChange = (value) => {
    setComplianceId(value);
  };

  const handleorderChange = (value) => {
    setOrderBy(value);
  };

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint1}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);


  

  const [openDeletePopover, setOpenDeletePopover] = useState(null);
const [publishSymbol, setPublishSymbol] = useState("");
  // Function to handle checkbox click
  const handleCheckboxClick = (item) => {
      // Toggle the popover visibility for the specific item
      setPublishSymbol(item.Symbol)
      if (openDeletePopover === item.id) {
          setOpenDeletePopover(null); // Close the popover if it's already open
      } else {
          setOpenDeletePopover(item.id); // Open the popover
      }
  };

  // Function to handle deletion confirmation
  const handlePublishConfirm = (id) => {
      // Perform your delete action here
      console.log(`Publish report with id: ${id}`);
      setOpenDeletePopover(null); // Close the popover after confirmation
  };

  const handleCheckboxChange = (e, item) => {
    setPublishSymbol(item.Symbol)
    //e.stopPropagation(); // Prevents the event from bubbling up
    // Handle the checkbox change here
    // You can check e.target.checked to see if it's checked or unchecked
};

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETFS</div>
        </div>

        <div className='header_table_filter'>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search2'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search' onChange={handleSearch}   value={searchInput} />
            </div>

            <div className='dropdown_style filter_by_country'>
            <SelectPicker
                    data={assetClassData}
                    searchable={false}
                    style={{ width:180 }}
                    placeholder="All Asset classes"
                    onChange={handleAssetClass} 
                    value={searchAsset} // Bind searchAsset to the value
                    />
            </div>

            <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <div className='dropdown_style'>
                <SelectPicker
                    data={orderData}
                    searchable={false}
                    placeholder="Order By"
                    style={{ width: 80 }}
                    onChange={handleorderChange}
                    value={orderby}
                    />
            </div>

            {/* <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Compliance statuses"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="Both Publish Statuses"
                    />
            </div> */}

            <a href='javascript:void(0)' className="btn_clear_filter"  style={{textDecoration:"none"}} onClick={handleClearFilters}>Clear Filters</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">

      {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
    <>
        <div className='table-responsive'>
          <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Published</th>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th>ETF Name</th>
                  <th style={{textAlign:'center'}}>Market</th>
                  <th style={{textAlign:'center'}}>Holdings</th>
                  <th style={{textAlign:'center'}}>Covered</th>
                  <th style={{textAlign:'center'}}>Uncovered</th>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th>Asset Class</th>
                  <th style={{textAlign:'center'}}>NCMPT Ratio</th>
                  <th>CMPT ratio</th>
                  <th style={{textAlign:'center'}}>DBTF ratio</th>
                  <th style={{textAlign:'center'}}>Interest ratio</th>
                  <th>Debt ratio</th>
                </tr>
              </thead>
              <tbody>

                
              {reportData.length > 0 ? (
  reportData.map((item, index) => (
                <tr>
                <td style={{textAlign:'center'}}>
                 
            

            <Whisper
                placement="right"
                trigger="click"
                open={openDeletePopover === item.id}
                onClose={() => setOpenDeletePopover(null)}
                speaker={
                    <Popover className="tooltip_form Rsuite_popover" preventOverflow>
                        <div className="tooltip_form_inner text-center">
                            <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                Are you sure you want to <br />
                                publish {publishSymbol} report?
                            </div>

                            <div className='btn__group mt-5'>
                                <a href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</a>
                                <a href="#" className='btn__assign' onClick={() => handlePublishConfirm(item.id)}>Confirm</a>
                            </div>
                        </div>
                    </Popover>
                }
            >
                <Button className="btn_assign" onClick={() => setOpenDeletePopover(item.id)}>
                    <div>
                        <Whisper placement="right" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Publish ETF Report From Here</Tooltip>} >
                        <label className="switchSmall">
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, item)} checked={item.Published} />
                        <small></small>
                    </label>
                        </Whisper>
                    </div>
                </Button>
            </Whisper>
        
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>{item.Symbol}</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>{item.ISIN}</td>
                  <td>
                  <Link to={`/ETFHolding/${item.Symbol}`} className='etf_name text_ellipsis'>{item.ETF_Name}</Link>
                  </td>
                  <td style={{textAlign:'center'}}>{item.Market}</td>
                  <td style={{textAlign:'center'}}>{item.Holdings}</td>   
                  <td style={{textAlign:'center'}}>{item.total_covered}</td>                
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    {item.Uncovered}
                  </div>    
                  </td>
                  <td style={{textAlign: 'center'}}>
  {item.is_leveraged === 1 ? (
    <Whisper
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      speaker={<Tooltip>Since the ETF is leveraged, it will be marked as non-compliant</Tooltip>}
    >
      <span className={`tag_td ${
        item.Compliance === "" || item.Compliance === null
          ? ''
          : item.Compliance === "CMPT"
          ? 'halal_tag'
          : item.Compliance === "NCMPT"
          ? 'not_halal_tag'
          : item.Compliance === "DBTF"
          ? 'doughtful_tag'
           : item.Compliance === "LEVERAGED"
          ? 'not_halal_tag'
          : ''
      }`}>
        {item.Compliance}
      </span>
    </Whisper>
  ) : (
    <span className={`tag_td ${
      item.Compliance === "" || item.Compliance === null
        ? ''
        : item.Compliance === "CMPT"
        ? 'halal_tag'
        : item.Compliance === "NCMPT"
        ? 'not_halal_tag'
        : item.Compliance === "DBTF"
        ? 'doughtful_tag'
        : ''
    }`}>
      {item.Compliance}
    </span>
  )}
</td>

                 

                  <td>{item.Asset_Class}</td>
                  <td style={{textAlign:'center'}}>{item.Non_COMPLIANT_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Halal_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Doubtful_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Interest_Ratio}</td>
                  <td style={{textAlign:'center'}}>{item.Debt_Ratio}</td>
                </tr> 
 ))
) : (
  <tr>
    <td colSpan="14" style={{ textAlign: "center", fontSize:20, paddingTop:30, paddingBottom:30 }}>
      No data available
    </td>
  </tr>
)}
            
              </tbody> 
          </table>
      
        </div>
        {totalPages > 1 && renderPagination()}
        </>
)}      
      </div>
    </div>
    </>
  );
}

export default ETFS;

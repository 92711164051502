import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Message ,Whisper, Tooltip  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link, useNavigate } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import { ReactComponent as IconStar } from "../images/IconStar.svg";
import debounce from 'lodash.debounce';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import Open_Link_Icon from "../images/Open_link_round.svg";
import IconJson from "../images/IconJSONAction.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';

function UncoveredStock() {
  const [tabName, setTabName] = useState('Uncovered Stock');
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);


  useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const navigate = useNavigate();
  const apiETFEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

  const [orderby, setOrderBy] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [sector, setSector] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [reportSource, setReportSource] = useState('');
  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info



  const [reportName, setReportName] = useState("");
  const [reportSymbol, setReportSymbol] = useState("");


  const handleQuaterChange = (selectedOption) => {
    setSelectedQuarter(selectedOption); // Update selectedPeriod state with the selected option
  };


  const fetchGlobalReport = async () => {
    setLoading(true);

        try {
      const apiUrl = `${apiETFEndPoint}/get_uncovered`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
          q:searchInput,
          order:orderby|| "DESC",
          country: countryCode || "",
          sector:sector,
        }
      };
      console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;
      if (status == "success") {
        setLoading(false);
        setReportCount(count);
        console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
        setReportData(data);
      } else {
        console.error("Error: ", message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchGlobalReport();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage,orderby,countryCode,sector]); // Regular effect for other dependencies



  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);
  
  const data2 = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 },
  ];


const handleSearch = (event) => {
  console.log(event.target.value);

  setSearchInput(event.target.value);
  setCurrentPage(1); // Reset to the first page for new search
};



const handleAddNewReport = async() => {
 
   // const reportSource = document.getElementById(`reportSource`).value;
    
    if(!reportSource)
    {
      setErrorMessage('Please Enter Report Year');
      setMessageType('error');
      return false;
    }
    if(!selectedQuarter)
    {
        setErrorMessage('Please Select Quater');
        setMessageType('error');
        return false;
    }
    


    const date = "";//The date is not Available on this uncovered stock API 
    const company_symbol = reportSymbol;
    const year = reportSource;
    const quarter = selectedQuarter;
    
    const taskCheckSuccess = await checkTask(company_symbol, reportSource, selectedQuarter);
    if (taskCheckSuccess) {
      const cancelButton = document.getElementById(`CancelReport`);
      if (cancelButton) {
        cancelButton.click();
      }
      await updateReportStatus(userid, company_symbol, date, quarter, year);
    }
  
};


const handleReportData = async(item) => {
  setReportName(item.name);
  setReportSymbol(item.stock_symbol);
};

const handleCancelReport = async(item) => {
  setSelectedQuarter("");
  setReportSource("");
};




const checkTask = async (company_symbol,reportSource,selectedQuarter) => {
      try {
          const res = await fetch(`${apiEndPoint}/check_task`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  data: {
                      user_id: userid,
                      company_symbol: company_symbol, // mandetory
                      quarter: selectedQuarter,
                      year: reportSource,
                  },
              }),
          });

          const result = await res.json();

          if (result.data.status === 'Success') {
            // setErrorMessage(result.data.message);
            // setMessageType('success');
            // return false;
            return true;
          } else {
            setErrorMessage(result.data.message);
            setMessageType('error');
            return false;
          }
      } catch (err) {
        console.log('Check Report : Error : ' + err.message);
        setErrorMessage('An error occurred while checking the task');
        setMessageType('error');
        return false; // Indicate failure
      }
};


const updateReportStatus = async (userid,company_symbol,date,quarter,year) => {
  const url = `${apiEndPoint}/update_tasks`;
  
  const payload = {
    data: {
      user_id: userid, // Replace this with the actual user_id if dynamic
      company_symbol: company_symbol, // Assuming itemId represents company_symbol
      date: date, // not mandetory
      quarter: quarter, // Replace this with the actual quarter if dynamic
      year: year, // Replace this with the actual year if dynamic
      status: progressStatus
    }
  };

  //console.log(JSON.stringify(payload));
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    const data = responseData.data; // Flatten the response
    if (data.status === "success") {
      //window.open(`/report/ancasdasdas`, '_blank');
     
        navigate(`/report/${data.data.encryptJSON}`);
      //
      return true; // Indicate success
    } else {
      console.error("API Error:", data.data.message);
      return false; // Indicate failure
    }
  } catch (error) {
    console.error("API Error:", error);
    return false; // Indicate failure
  }
};


const renderPagination = () => {
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
        <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
      </li>
    );
  }

  return (
    <>
  
    <nav aria-label="..." id='pagination_row' style={{display:'flex', alignItems:'center', gap:60}}>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
            <i className='fa fa-angle-double-left'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
            <i className='icon-prev'></i>
          </a>
        </li>
        {pages}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
            <i className='icon-next'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
            <i className='fa fa-angle-double-right'></i>
          </a>
        </li>
      </ul>

   
      <div style={{color:"#888"}}><span >Total Records : </span>{reportCount}</div>
   
    </nav>
   
    
    </>
  );
};

const handleClearFilters = () => {
  setCurrentPage(1);
  setSearchInput("");
  setCountryCode("");
  setSector("");
  setOrderBy("");
};



const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 4000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};


const handleChange = (e) => {
  const value = e.target.value;

  // Check if the value is a number and does not exceed 4 digits
  if (/^\d{0,4}$/.test(value)) {
    setReportSource(value); // Update state only if the value is valid
  }
};

useEffect(() => {
  const fetchCountryList = async () => {
    const url = `${apiETFEndPoint}/get_country_list_etf`;
    const payload = {
      data: {
        user_id: userid // Replace with the actual user_id if dynamic
      }
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      const formattedCountryData = data.data.map((country) => ({
        label: country.country_name,
        value: country.country_code,
      }));

      setCountryData(formattedCountryData);

    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  fetchCountryList();
}, []);

useEffect(() => {
  const fetchCountryList = async () => {
    const url = `${apiETFEndPoint}/getSectorList`;
    const payload = {
      data: {
        user_id: userid // Replace with the actual user_id if dynamic
      }
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      const formattedCountryData = data.data.map((Sector) => ({
        label: Sector.Sector,
        value: Sector.Sector,
      }));

      setSectorData(formattedCountryData);

    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  fetchCountryList();
}, []);




const orderData = ['ASC', 'DESC'].map(
  item => ({ label: item, value: item })
);

const handleorderChange = (value) => {
  setOrderBy(value);
};


const handleCountryChange = (value) => {
  setCurrentPage(1);
  setCountryCode(value);
};

const handleSectorChange = (value) => {
  setCurrentPage(1);
  setSector(value);
};



  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Uncovered Stock</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>
          <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={sectorData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Sector"
              onChange={handleSectorChange}
              value={sector}
            />
          </div>

          <div className='dropdown_style'>
                <SelectPicker
                    data={orderData}
                    searchable={false}
                    placeholder="Order By"
                    style={{ width: 80 }}
                    onChange={handleorderChange}
                    value={orderby}
                    />
            </div>


          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={25} columns={10} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr >
                  <th>Symbol</th>
                  <th>Company Name</th>
                  <th>Market Cap</th>
                  <th>Exchange</th>
                  <th>ISIN</th>
                  <th style={{ textAlign: 'center' }}>Count</th>
                  <th>Sector</th>
                  <th>Industry</th>                  
                  <th style={{ textAlign: 'center' }}>Employee Total</th>
                  <th style={{ textAlign: 'center' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map((item, index) => (
                  <tr key={index} >
                    <td>{item.stock_symbol}</td>
                    <td>{item.name}</td>
                    <td>{item.marketCapitalization ? `${item.marketCapCurrency} ${item.marketCapitalization}` : ''}</td>
                    <td>{item.exchange}</td>
                    <td>{item.isin}</td>
                    <td style={{ textAlign: 'center' }}>{item.uncovered_count}</td>
                    <td>{item.gsector}</td>
                    <td>{item.finnhubIndustry}</td>
                    <td style={{ textAlign: 'center' }}>{item.employeeTotal}</td>
                    <td>
                      <div className='action_row' style={{justifyContent:'center'}}>
                      <h5> <Link className='btn_create_report' data-bs-toggle="modal" data-bs-target={`#CreateReportModal`} onClick={() => handleReportData(item)}> Create Report </Link> </h5>  
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center", fontSize:20, paddingTop:30, paddingBottom:30 }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

            {/* Modal */}
    <div className="modal fade duplicate_form_modal" id={`CreateReportModal`} tabIndex="-1" aria-labelledby={`DuplicateFormModalLabel`} aria-hidden="true">
    {renderMessage()}
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{reportName}</h1>
          </div>
          <div className="modal-body">
            <div className='duplicate_form_content'>
              <div className='duplicate_form_row'>
                <div className='duplicate_form_col'>
                  <label>Report Source</label>
                  {/* <input type='text' id={`reportSource_${item.row_id}`} className='form-control' placeholder='YYYY' /> */}
                  {/* <input type='text' id={`reportSource`} className='form-control' placeholder='YYYY' /> */}
                  <input maxLength={4}  type="text" id="reportSource" className='form-control'  value={reportSource} onChange={handleChange}
 />
                </div>

                <div className='duplicate_form_col'>
                  <label>Filling Type</label>
                  <div className='select_drop_picker'>
                    <SelectPicker
                      data={data2}
                      searchable={false}
                      style={{ width: 250 }}
                      placeholder="Quarter"
                      onChange={handleQuaterChange}
                      value={selectedQuarter}
                    />
                  </div>
                </div>
              </div>

              <div className="btn__group">
                {/* <button type="button" className="btn_cancel" data-bs-dismiss="modal" id={`Cancel_${item.row_id}`}>Cancel</button>
                <button type="button" className="btn_duplicate btn_add_new_report" onClick={() => handleAddNewReport(item.row_id)}>Add New Report</button> */}

                <button type="button" className="btn_cancel" data-bs-dismiss="modal" id={`CancelReport`} onClick={() => handleCancelReport()}>Cancel</button>
                <button type="button" className="btn_duplicate btn_add_new_report" onClick={() => handleAddNewReport()}>Add New Report</button>


                {/* <button type="button" className="btn_duplicate btn_add_new_report" onClick={() => handleAddNewReport(company.row_id)}>Add New Report</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Modal */}


          {totalPages > 1 && renderPagination()}


        </>    
)}
        </div>
      </div>

    </>
  );
}

export default UncoveredStock;
